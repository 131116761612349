/*
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2023.
 */
import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import { footerContainerSX } from '@/components/content/Footer/styles/container';
import { useFooter } from '@/data/Content/Footer';
import { useSettings } from '@/data/Settings';
import { ID } from '@/data/types/Basic';
import { Paper } from '@mui/material';
import { FC, useEffect } from 'react';

export const Footer: FC<{
	id: ID;
	clickAction?: () => void;
}> = ({ id }) => {
	const { settings } = useSettings();
	const { contentItems } = useFooter(id);

	useEffect(() => {
		const digicertScript = document.createElement('script');
		digicertScript.src = '//seal.digicert.com/seals/cascade/seal.min.js';
		document.body.appendChild(digicertScript);

		return () => {
			if (digicertScript.parentNode === document.body) {
				document.body.removeChild(digicertScript);
			}
		};
	}, []);

	return (
		<Paper
			component="footer"
			sx={footerContainerSX(settings?.csrSession)}
			{...(settings?.csrSession && { 'data-iframe-height': '' })}
		>
			{contentItems.map((properties) => (
				<ContentRecommendation
					key={properties.emsName}
					id={`${id}-${properties.emsName}`}
					properties={properties}
				/>
			))}
		</Paper>
	);
};
